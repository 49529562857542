<template>
  <div :class="type">
    <shared-index
      :columns="columns"
      :actions="actions"
      :type="type"
      :titles="titles"
      :header-actions="headerActions"
      :filterable="false"
    />
  </div>
</template>

<script>
import SharedIndex from '@/components/shared/index'

export default {
  name: 'Comment',
  components: {
    SharedIndex
  },
  data() {
    return {
      type: 'comment',
      titles: [{ label: 'BÌNH LUẬN ĐÃ DUYỆT', value: 'true' }, { label: 'BÌNH LUẬN CHƯA DUYỆT', value: 'false' }],
      columns: ['stt', 'c_object', 'c_creator', 'content', 'c_approved', 'c_created_at', 'actions'],
      actions: ['browser', 'unbrowser', 'delete'],
      headerActions: ['reset', 'export']
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
